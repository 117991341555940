
import { defineComponent } from 'vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import debounce from '@/components/debounce';

export default defineComponent({
  name: 'BasePagination',
  emits: ['change-page'],
  components: {
    BaseIcon
  },
  setup() {
    return {
      ...debounce(),
    }
  },
  props: {
    totalPages: {
      type: Number,
      required: true
    },
    pageNumber: {
      type: Number,
      required: true
    },
    extraSearch: Boolean,
  },
  data: () => ({
    inputValue: null as null | number
  }),
  methods: {
    updateInputValue(event: InputEvent) {
      let value = Number((event.target as HTMLInputElement).value)

      if (value >= this.totalPages) {
        value = (this.totalPages - 1)
      } else if (value < 1) {
        value = 0
      } else {
        value = value - 1
      }

      this.inputValue = value + 1
      console.log(value )
      this.changePage(value)
    },
    prevPage() {
      if (this.pageNumber) {
        this.changePage(this.pageNumber - 1)
      }
    },
    nextPage() {
      if ((this.pageNumber + 1) !== this.totalPages) {
        this.changePage(this.pageNumber + 1)
      }
    },
    changePage(page: number) {
      this.$emit('change-page', {
        pageNumber: page,
      })
    }
  },
})
