
import {defineComponent, ref, computed, watch, onMounted} from 'vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import debounce from "@/components/debounce";

export default defineComponent({
  name: 'BaseCheckbox',
  props: {
    value: {
      type: String,
      default: () => null
    },
    type: {
      type: String,
      default: () => 'checkbox'
    },
    checked: {
      type: Boolean,
      required: false,
      default: () => null
    },
    id: Number || String,
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    setChecked(id: number | string) {
      this.$emit('setChecked', id)
    }
  }
})
