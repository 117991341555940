<template>
  <div
    @blur="stateDropdown = false"
    tabindex="-1"
    class="dropdown-ellipsis"
  >
    <img
      id="dropdown-ellipsis"
      @click="changeDropdownState"
      :src="require('@/assets/icons/menu.png')"
      class="item"
      :class="{
        'dropdown-ellipsis__disabled': isDisabled,
        'pointer': !isDisabled,
      }"
      alt="img"
    />
    <div v-if="stateDropdown" :class="classListDropdown">
      <div
        class="dropdown__item"
        v-for="option in options"
        @click="option.method"
      >{{option.name}}</div>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'DropdownEllipsis',
  props: {
    options: {
      type: Array,
      default: () => null
    },
    isDisabled: Boolean,
    position: {
      type: String,
      default: () => 'bottom'
    }
  },
  setup(props) {
    const stateDropdown = ref(false)

    function closeDropdown() {
      stateDropdown.value = false
    }

    function changeDropdownState() {
      if (!props.isDisabled) {
        stateDropdown.value = true
      }
    }

    const classListDropdown = computed(() => {
      return [
        'dropdown',
        `dropdown__position_${props.position}`
      ]
    })

    return {
      classListDropdown,
      stateDropdown,
      closeDropdown,
      changeDropdownState,
    }
  }
})
</script>
<style lang="scss" scoped>
.dropdown-ellipsis {
  outline: none;
  position: relative;
  margin: auto 0 auto auto;

  &__disabled {
    cursor: no-drop;

  }
  .item:hover {
    background-color: $color-grey-5;
  }
  .dropdown {
    position: absolute;
    z-index: 2;
    min-width: 12rem;
    border: 1px solid $color-grey-4;
    @extend .c-bg-w;

    &__position {
      &_right {
        left: 0;
      }

      &_bottom {
        left: revert;
        right: 0;
      }
    }


    > div {
      padding: 10px;
      min-height: 30px;
      cursor: pointer;
    }

    > div:hover {
      background-color: $color-grey-6;
    }

    :not(:last-child) {
      border-bottom: .1rem solid $color-grey-4;
    }
  }
}
</style>
