
import { defineComponent } from 'vue';
import BaseTable from '@/components/ui/BaseTable.vue';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';
import { ConvertDate } from '@/components/converters/date';
import { Certificate, CertificateTypes, certificateTypes } from '@/models/CertificateModel';
import AddCertificateModal from '@/components/modals/AddCertificateModal.vue';
import BasePagination from '@/components/ui/BasePagination.vue';
import certificates from '@/services/certificates';
import CertificateFiltersModal
  from '@/components/modals/CertificateFiltersModal.vue';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import CertificateTypesCheckboxes from '@/components/certificates/CertificateTypesCheckboxes.vue';
import prices from '@/services/prices';
import timeService from '@/services/timeService';
export default defineComponent({
  name: 'CurrentCertificates',
  components: {
    BaseCheckbox,
    BasePagination,
    AddCertificateModal,
    BaseTable,
    DropdownEllipsis,
    CertificateFiltersModal,
    CertificateTypesCheckboxes
  },
  data: () => ({
    file: null as any,
    range: null as any,
    isLoadingUploadReport: false,
    pageable: {
      pageNumber: 0,
      pageSize: 20,
      totalPages: null
    },
    checkedType: CertificateTypes.CERTIFICATE as keyof typeof CertificateTypes
  }),
  computed: {
    getReportName(): any {
      switch (this.checkedType) {
        case 'CERTIFICATE':
          return "Артикулы в сертификате"
        case 'DECLARATION':
          return "Артикулы в ДС"
        case 'REFUSAL':
          return "Артикулы в ОР"
      }
    },
    dropdownOptions(): any {
      const certificateOptions = (item: Certificate) => [
        {
          name: 'Удалить',
          method: () => this.deleteCertificate(item)
        },
        {
          name: item.link ? 'Добавить другой файл' : 'Добавить файл',
          method: () => this.addFileCertificate(item)
        }
      ]

      return certificateOptions
    },
    certificates(): Array<Certificate> {
      return this.$store.getters.getCertificates
    },
    headers(): any {
      return [
        {
          title: 'Номер',
          content: (item: Certificate) => item.number
        },
        ...(this.checkedType === CertificateTypes.CERTIFICATE || this.checkedType === CertificateTypes.DECLARATION
            ? [{
              title: 'Дата',
              content: (item: Certificate) => `${item.fileDate ? ConvertDate(item.fileDate) + '-' : ''}${ConvertDate(item.expiredAt)} ${item.isExpired ? '(просрочен)' : ''}`
            }]
            : [{
              title: 'Дата',
              content: (item: Certificate) => item.fileDate ? `${ConvertDate(item.fileDate)}` : '-'
            }]
        ),
        {
          title: 'Автор',
          content: (item: Certificate) => item.user
        },
        {
          title: 'Ссылка на файл',
          content: (item: Certificate) => item.link ? 'Скачать файл' : 'Файл не добавлен'
        }
      ]
    }
  },
  methods: {
    downloadReportByType() {
      switch (this.checkedType) {
        case CertificateTypes.CERTIFICATE:
          this.openCertificateFiltersModal()
          break;
        case CertificateTypes.DECLARATION:
          this.downloadDeclarationReportArticles()
          break;
        case CertificateTypes.REFUSAL:
          this.downloadRefusalReportArticles()
          break;
      }
    },
    async downloadDeclarationReportArticles() {
      const table = await certificates.declarationReportArticles(CertificateTypes.DECLARATION) as any
      let date = timeService.createDateForViewFromUTCTime();
      await this.$store.dispatch('exportFile', {
        data: table.data,
        fileName: `declaration_${date}.xls`
      })
    },
    async downloadRefusalReportArticles() {
      const table = await certificates.refusalReportArticles(CertificateTypes.REFUSAL) as any
      let date = timeService.createDateForViewFromUTCTime();
      await this.$store.dispatch('exportFile', {
        data: table.data,
        fileName: `refusal_${date}.xls`
      })
    },
    async downloadReportDeclaration(item: any) {
      const table = await certificates.declarationReport({ fileId: item.id }) as any
      let date = timeService.createDateForViewFromUTCTime();
      const blob = new Blob([table.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `declaration_${date}.xls`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openCertificateFiltersModal() {
      const element = document.getElementById('modal-certificate-filters-modal')!;
      element.style.display = 'flex'
    },
    closeCertificateFiltersModal() {
      const element = document.getElementById('modal-certificate-filters-modal')!;
      element.style.display = 'none'
    },
    async generateTableCertificates(infoFilters: any) {
      let subgroups = [] as any
      this.isLoadingUploadReport = true

      if (infoFilters.certificatedProductGroups.length) {
        infoFilters.certificatedProductGroups.map((certificatedPg: any) => {
          subgroups = [
            ...subgroups,
            ...certificatedPg.subgroups,
          ]
        })
      } else {
        const allCertificatedPg = await certificates.searchProductGroupsByCertificates()

        allCertificatedPg.map((certificatedPg: any) => {
          subgroups = [
            ...subgroups,
            ...certificatedPg.subgroups,
          ]
        })
      }

      const payload = {
        subgroups: subgroups.map((productGroup: any) => productGroup.id),
        type: this.checkedType
      }

      this.$store.dispatch('certificateExcelReport', payload).then(() => {
        this.closeCertificateFiltersModal()
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
      }).catch(() => {
        this.closeCertificateFiltersModal()
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
      }).finally(() => {
        this.isLoadingUploadReport = false
      })
    },
    async addFileCertificate(item: any) {
      const container: HTMLElement | null = document.getElementById('main')
      const input = document.createElement('input')

      input.type = 'file'
      input.style.display = 'none'

      if (container) {
        container.appendChild(input);
        input.click()

        input.addEventListener('change', async (event: any) => {
          await this.$store.dispatch('addCertificateFile', {
            ...item,
            files: event.target.files
          })
        })

        container.removeChild(input)
      }
    },
    downloadFile(url: string) {
      if (url) {
        const href = `${process.env.VUE_APP_URL_FILES}${url.split('\\').join('/')}`
        const link = document.createElement('a');
        link.setAttribute('href', href);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
      }
    },
    openUploadCertificateModal() {
      const element = document.getElementById('modal-upload-certificates')!;
      element.style.display = 'flex'
    },
    showModalAddingCertificate() {
      const element = document.getElementById('modal-add-certificate')!;
      element.style.display = 'flex'
    },
    async deleteCertificate(item: Certificate) {
      await this.$store.dispatch('deleteCertificate', item.id)
    },
    async getCertificates(nextPageable: { pageNumber: number }, type: keyof typeof CertificateTypes) {
      const { pageable: { pageNumber }, totalPages } = await this.$store.dispatch('fetchCertificates', {
        ...nextPageable,
        pageSize: this.pageable.pageSize,
        type
      })

      this.pageable = {
        ...this.pageable,
        pageNumber,
        totalPages
      }
    },
    async reselectCertificateType(chosenType: keyof typeof CertificateTypes) {
      await this.getCertificates({ pageNumber: 0 }, chosenType)
      this.checkedType = chosenType
    }
  },
  async mounted() {
    await this.getCertificates(this.pageable, this.checkedType)
  }
})
