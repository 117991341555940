
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import { DatePicker } from 'v-calendar';
import { convertSimpleDate } from '@/components/converters/date';

export default defineComponent({
  name: 'AddCertificateModal',
  components: {
    ModalWrapper,
    DatePicker,
  },
  data: () => ({
    certificateData: {
      name: null,
      number: null,
      files: null,
      range: null,
    } as any
  }),
  computed: {
    isDisabled(): boolean {
      return Object
        .values(this.certificateData)
        .some((value: any) => value === null)
    }
  },
  methods: {
    setDefault() {
      const inputRef = this.$refs.fileInput as InstanceType<typeof HTMLInputElement>
      inputRef.value = ''

      Object.keys(this.certificateData).map((key: string) => {
        this.certificateData[key] = null
      })
    },
    async saveCertificate() {
      await this.$store.dispatch('addCertificate', this.certificateData)
      this.setDefault()
    },
    setFile(event: any) {
      this.certificateData.files = event.target.files || event.dataTransfer.files;
    },
  },
})
