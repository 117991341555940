
import { defineComponent } from 'vue';
import { CertificateTypes, certificateTypes } from '@/models/CertificateModel';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';

export default defineComponent({
  name: 'CertificateTypesCheckboxes',
  components: {
    BaseCheckbox
  },
  data: () => ({
    checkedType: CertificateTypes.CERTIFICATE as keyof typeof CertificateTypes
  }),
  methods: {
    checkType(key: any) {
      this.checkedType = key
      this.$emit('reselectCertificateType', key)
    },
  },
  computed: {
    certificateTypes(): { [K in CertificateTypes]: string } {
      return certificateTypes
    },
    isTypeChecked() {
      return (key: string) => {
        return this.checkedType === key
      }
    }
  },
  async mounted() {
  }
})
