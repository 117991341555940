
import { defineComponent } from 'vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import BaseInput from '@/components/ui/BaseInput.vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import BaseDatePicker from '@/components/ui/BaseDatePicker.vue';
import BaseCheckbox from '@/components/ui/BaseCheckbox.vue';
import Spinner from '@/components/Spinner.vue';
import { CertificateTypes } from '@/models/CertificateModel';

export default defineComponent({
  name: 'CertificateFiltersModal',
  components: {
    Spinner,
    SearchDropdown,
    BaseInput,
    ModalWrapper,
    BaseDatePicker,
    BaseCheckbox,
  },
  props: {
    isLoading: Boolean,
    status: Boolean,
    productGroup: Boolean,
    productSubGroup: Boolean,
    onlyCertificatedProductGroup: Boolean,
    approved: Boolean,
    brand: Boolean,
    article: Boolean,
    titleModal: {
      type: String,
      default: () => ''
    },
    titleBtn: {
      type: String,
      default: () => ''
    },
    certificateType: {
      type: String,
      default: () => CertificateTypes.CERTIFICATE
    }
  },
  data: () => ({
    filtersData: {
      brand: null,
      productGroup: null,
      productSubGroup: null,
      certificatedProductGroup: null,
      certificatedProductSubGroup: null,

      selectedBrands: [],
      selectedProductGroups: [],
      selectedProductSubGroups: [],
      selectedCertificatedProductGroups: [],
      selectedCertificatedProductSubGroups: [],

      isApproved: false,
    } as any
  }),
  computed: {
    truncateProductName() {
      return (productGroup: any) => {
        return productGroup.name.length > 30
          ? `${productGroup.name.slice(0, 30)}...`
          : productGroup.name
      }
    }
  },
  methods: {
    deleteItem(field: string, item: any) {
      this.filtersData[field] = this.filtersData[field].filter((currentItem: any) => {
        return item && item.brandId
          ? item.brandId !== currentItem.brandId
          : item.id !== currentItem.id
      })
    },
    setItem(titleField: string, field?: string, item?: any) {
      if (titleField === 'productGroup') {
        this.filtersData.selectedProductSubGroups = []
        this.filtersData.productSubGroup = null
      }

      if (item && titleField === 'certificatedProductGroup') {
        this.filtersData.selectedCertificatedProductSubGroups = []
        this.filtersData.certificatedProductSubGroup = null
      }

      if (field && item) {
        this.filtersData[field].push(item)
        this.filtersData[titleField] = ''
      }

      if (titleField === 'approved') {
        this.filtersData.isApproved = !this.filtersData.isApproved
      }
    },
    closeModal() {
      this.setDefault()
      this.$emit('closeModal')
    },
    setDefault() {
      this.filtersData = {
        brand: null,
        productGroup: null,
        productSubGroup: null,
        certificatedProductGroup: null,
        certificatedProductSubGroup: null,
        selectedBrands: [],
        selectedProductGroups: [],
        selectedProductSubGroups: [],
        selectedCertificatedProductGroups: [],
        selectedCertificatedProductSubGroups: [],
      }
    },
    async setFilters() {
      this.$emit('setFilters', {
        brands: this.filtersData.selectedBrands,
        productGroups: this.filtersData.selectedProductGroups,
        certificatedProductGroups: this.filtersData.selectedCertificatedProductGroups,
        productSubGroups: this.filtersData.selectedProductSubGroups,
        certificatedProductSubGroups: this.filtersData.selectedCertificatedProductSubGroups,
        isApproved: this.filtersData.isApproved,
      })
      this.closeModal()
    },
  }
})
